import styled from "styled-components";
import { mediaQuery } from "../../assets/styles/mixins";

export const Container = styled.main`
  .creditPrivate-content {
    margin-top: 80px;
    overflow: hidden;
    align-items: center;

    ${mediaQuery.desktop`
      margin-top: 150px;
      display: flex;
      justify-content: space-between;
    `}

    .text-wrapper {
      width: 100%;
      text-align: center;

      ${mediaQuery.desktop`
         width: 561px;
         text-align: left;
      `}

      h1 {
        font-size: 26px;
        color: #333;
        font-weight: 600;
        margin-bottom: 20px;

        ${mediaQuery.desktop`
          font-size: 40px;
          margin-bottom: 28px;
          line-height: 53px;
      `}
      }

      p {
        font-size: 16px;
        color: #757474;

        &.spotlight {
          margin-top: 28px;
          color: #23797b;
          font-weight: 600;
        }
      }

      &.text-wrapper__list {
        margin-bottom: 30px;

        ${mediaQuery.desktop`
          margin-bottom: 0px;
        `}

        h1 {
          margin-bottom: 20px;

          ${mediaQuery.desktop`
            margin-bottom: 90px;
          `}
        }
      }
    }

    &:before,
    &:after {
      display: none;
    }

    img {
      width: 100%;
      margin: 0 auto 80px;

      ${mediaQuery.desktop`
         width: 429px;
         margin: 0;
      `}

      &.CelularFrente {
        ${mediaQuery.desktop`
          width: 392px;
        `}
      }
    }

  }

  .highlight-home {
    margin: 0;

    ${mediaQuery.desktop`
      margin: 150px 0;
      border: 1px solid transparent;
    `}

    article.jumbotron {
      margin-top: 166px;
      padding: 38px;
      margin-left: right;

      width: 100%;

      ${mediaQuery.desktop`
        width: 750px !important;
      `}

      display: flex;
      align-items: center;
      justify-content: center;
      align-items: flex-start;
      text-align: center;

      &:before {
        display: none;

        ${mediaQuery.desktop`
          display: inherit;
        `}
      }

      ${mediaQuery.desktop`
        padding: 80px;
        width: 520px;
        text-align: left;
      `}

      h2 {
        font-size: 26px;

        ${mediaQuery.desktop`
          font-size: 40px;
        `}
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }

      > button {
        margin-top: 30px;
        width: 100%;
        background-color: #fff !important;
        color: #23797B !important;
        font-weight: 600;
        text-transform: uppercase;

        svg path {
          fill: #23797B;
        }

        &:hover {
          background-color: #23797B !important;
          color: #fff !important;

          svg path {
            fill: #fff;
          }
        }

        ${mediaQuery.desktop`
          width: auto;
        `}
      }
    }
  }

  .creditPrivate-content-form {
    background: #f9f8f7;

    .container {
      background: #f9f8f7;
    }

    .title-section {
      
      margin: 0 auto;

      ${mediaQuery.desktop`
        width: 675px;
      `}
    }
  }

  .benefits {
    h1 {
      text-align: center;
      font-size: 26px;
      margin: 0 0 20px;
      font-weight: 600;

      ${mediaQuery.desktop`
        font-size: 40px;
        margin: 150px 0 50px;
      `}
    }
  }

  .form-contact {
    padding: 0 36px !important;
    margin: 0 auto;
    width: 100%;

    ${mediaQuery.desktop`
      width: 765px;
    `}

    ${mediaQuery.desktop`
        padding: 0 80px 80px;
    `}

    .button-block {
      display: flex;
      justify-content: center;
    }

    button {
      margin-top: 61px;
      width: 100%;
      border: 2px solid ${({ theme }) => theme.font.third};
      min-height: 53px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: uppercase;

      ${mediaQuery.desktop`
        width: 122px;
      `}
    }

    .phone {
      font-size: 16px;
      color: #707070;
      margin: 50px auto 80px;
      text-align: center;

      ${mediaQuery.desktop`
        margin: 50px auto 150px;
      `}

      a {
        font-weight: 600;
        color: #707070;
      }
    }

    .form__recaptcha {
      margin-top: 50px;

      display: flex;
      justify-content: center;
    }
  }

  .input-group {
    display: block;

    ${mediaQuery.desktop`
      display: flex;
      justify-content: space-between;
    `}

    .input-block {
      width: 100% !important;

      ${mediaQuery.desktop`
        width: 320px !important;
      `}
    }

    input {
      background: #f9f8f7;
    }
  }

  .container.CreditPrivate__next-page {
    justify-content: center;
    width: 90%;
    padding: 80px 0 0px;

    ${mediaQuery.desktop`
       display: flex;
       padding: 100px 0 150px;
    `}

    button {
      font-weight: 600;
      text-transform: uppercase;
      min-height: 53px;
      width: 100%;
      margin-bottom: 80px;

      ${mediaQuery.desktop`
       display: flex;
       width: auto;
       margin-bottom: 0px;
      `}
    }
  }


  .activeBtn {
    border: 2px solid ${({ theme }) => theme.font.third}!important;
  }
`;
