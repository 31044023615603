import React, { Children } from "react";

import Jumbotron from "../Jumbotron";
import Button from "../Button";

import { BannerPros } from "./interface";

import { Container } from "./styles";

const Banner = ({
  backgroundImg,
  title,
  text,
  onClick,
  textButton,
  containerClass,
  classJumbotron,
  children,
}: BannerPros) => {
  return (
    <Container className={containerClass} backgroundImg={backgroundImg}>
      <div className="container container-jumbotron">
        <Jumbotron className={classJumbotron} title={title} text={text}>
          {children}
          {textButton && (
            <Button onClick={onClick} className="btn-banner-home">
              {textButton}
            </Button>
          )}
        </Jumbotron>
      </div>
    </Container>
  );
};

export default Banner;
