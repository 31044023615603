import React from "react";

import { Container } from "./styles";

import { CheckListProps } from "./interface";

import btCheckGreen from "../../assets/images/bt-check-green.svg";

const CheckList = ({ headers, item, activeBtnClass, className }) => {
  const [contentCards, setContentCards] = React.useState(item);
  const [active, setActive] = React.useState(0);

  React.useEffect(() => {
    setContentCards(item[0].content);
  }, []);

  function handleSelection(i) {
    setContentCards(item[i].content);
    setActive(i);
  }

  return (
    <Container>
      <div className={`container ${className}`}>
        <div className="accordion__headers">
          {headers &&
            headers.map((header: any, i) => (
              <button
                className={`accordion__headersBtn ${
                  active === i ? activeBtnClass : ""
                }`}
                key={header.btnText}
                type="button"
                onClick={() => handleSelection(i)}
              >
                {header.btnText}
              </button>
            ))}
        </div>
        <ul>
          {contentCards?.map((item, index) => (
            <li key={index}>
              <span>
                <img src={btCheckGreen} alt="check item" />
              </span>
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default CheckList;
