import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';


export const Container = styled.article`
  margin: 0 auto;
  width: 100%;
  position: relative;

  ${mediaQuery.desktop`
    width: 562px;
    position: relative;
    margin-top: -40px;
  `}
  
  li {
    font-size: 14px;
    color: #757474;
    margin-bottom: 30px;
    padding-left: 52px;
    padding-top: 2px;
    position: relative;
    text-align: left;

    ${mediaQuery.desktop`
      font-size: 16px;
    `}

    span {
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border-radius: 50% 50%;
      background: #CCDD00;
      color: #fff;
      font-size: 20px;
      text-align: center;
      line-height: 32px;
    }
  }
`;
