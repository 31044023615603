import React from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { navigate } from "gatsby";

import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import TitleSection from "../../components/TitleSection";
import NumericList from "../../components/Numeric-list";
import CheckList from "../../components/Check-list";
import Highlights from "../../components/Highlights";
import Jumbotron from "../../components/Jumbotron";
import Input from "../../components/Input";
import Message from '../../components/Message';
import Accordion from "../../components/Accordion";
import IcCheck from '../../assets/images/ic_check.svg';

import { Container } from "./styles";

import bgCelularApp from "../../assets/images/celular-com-app-de-consignado-zipdin.png";
import bgCelular2App from "../../assets/images/celular-com-app-de-consignado-zipdin-2.png";
import bgCelularFrenteApp from "../../assets/images/celular-de-frente-com-app-zipdin.png";
import bgCelularFrente2App from "../../assets/images/celular-de-frente-com-app-zipdin-2.png";
import bgConsigned from "../../assets/images/bg-consigned-private.jpg";
import bgConsignedMobile from "../../assets/images/bg-consigned-private-mobile.jpg";
import bgHighlightPrivate from "../../assets/images/notebook-private.png";
import bgHighlightPrivateMobile from "../../assets/images/notebook-private-mobile.png";

import { useWindowSize } from "../../hooks/resizeWindow";
import {
  contentCreditPrivate,
  headersAccordion,
  questionsAccordion,
  benefitsCompany,
  headersBenefit,
} from "../../content/pages/CreditPrivate";

import handleValidate from "../../helpers/validations/formValidations";
import templateEmail from "../../helpers/templates/indicateEmail";

const CreditPrivate = () => {
  const recaptchaRef = React.useRef();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [fone, setFone] = React.useState("");
  const [empresa, setEmpresa] = React.useState("");
  const [recaptcha, setRecaptcha] = React.useState("");
  const [validateFields, setValidateFields] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);

  React.useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidateFields(true);

    if (!!name && !!email && !!fone && !!empresa && !!recaptcha) {
      const emailFormated = templateEmail({ name, email, fone, empresa });

      await axios.post(
        "https://app-consig.zipdin.com.br/app-consig/services/email/send",
        emailFormated
      );

      setShowMessage(true);
      setValidateFields(false);

      setName("");
      setEmail("");
      setFone("");
      setEmpresa("");

      return;
    }
  };

  const size = useWindowSize();

  function handleNextPage() {
    navigate("/contato");
  }

  return (
    <Layout>
      <Container>
        <Banner
          title="Crédito Consignado Privado"
          text="Com o Crédito Consignado Zipdin os colaboradores de empresas privadas têm acesso às melhores condições do mercado. A contratação é simples e rápida: por meio do App Zipdin ou telefone, e o desconto é feito direto no contracheque."
          backgroundImg={size.width > 800 ? bgConsigned : bgConsignedMobile}
          classJumbotron="info-banner col-md-7"
        />
        <section className="creditPrivate-content container">
          <img src={bgCelular2App} width="429" alt="Celular com App de Consignado Zipdin" />
          <div className="text-wrapper">
            <h1>
              Somos a primeira fintech de Crédito Consignado a disponibilizar a
              contratação pelo celular
            </h1>
            <p>
              A tecnologia Zipdin garante que o colaborador não precise ir ao
              banco. É só enviar os documentos pelo celular e resolver tudo de
              onde estiver e ainda é possível acompanhar tudo pelo app.
            </p>
            <p className="spotlight">Mais fácil, rápido e prático.</p>
          </div>
        </section>
        <section className="creditPrivate-content container">
          <div className="text-wrapper text-wrapper__list">
            <h1>Como funciona?</h1>
            <NumericList item={contentCreditPrivate.howWork} />
          </div>
          <img
            src={bgCelularFrente2App}
            className="CelularFrente"
            alt="Celular de frente com App Zipdin"
          />
        </section>
        <section className="benefits">
          <h1>Vantagens</h1>
          <CheckList
            activeBtnClass="active"
            className="checklist"
            headers={headersBenefit}
            item={benefitsCompany}
          />
        </section>
        <Highlights
          className=" highlight-home"
          orientation="right"
          imageBg={
            size.width > 800 ? bgHighlightPrivate : bgHighlightPrivateMobile
          }
        >
          <div id="pj-as-service" className="container">
            <Jumbotron
              className=" jumbotron"
              title="Quer montar a sua própria Operação de Consignado?"
              text="A Zipdin tem toda a experiência necessária para ajudar você a dar esse passo. Podemos disponibilizar uma solução completa ou modular de acordo com a sua necessidade. Temos a expertise e tecnologia para proporcionar tranquilidade e rentabilidade para os investidores e as melhores condições para os colaboradores."
            >
              <Button onClick={() => handleNextPage()}>saiba mais</Button>
            </Jumbotron>
          </div>
        </Highlights>
        <section className="creditPrivate-content-form">
          <TitleSection
            title="Indique sua empresa para o Consignado Zipdin"
            text="Informe os dados do responsável da sua empresa que entraremos em contato."
          />
          <div className="container">
            <form className="form-contact" onSubmit={handleSubmit}>
              <div className="input-group">
                <div className="input-block">
                  <Input
                    name="name"
                    type="text"
                    label="Nome completo do responsável"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={validateFields && handleValidate(!name)}
                    errorMsg="Precisamos dessa informação."
                  />
                </div>
                <div className="input-block">
                  <Input
                    name="email"
                    type="email"
                    label="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={validateFields && handleValidate(!email)}
                    errorMsg="Precisamos de um e-mail válido."
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-block">
                  <Input
                    name="fone"
                    type="tel"
                    label="Telefone com DDD"
                    value={fone}
                    onChange={(e) => setFone(e.target.value)}
                    error={validateFields && handleValidate(!fone)}
                    errorMsg="Precisamos de um telefone válido"
                  />
                </div>
                <div className="input-block">
                  <Input
                    name="Empresa"
                    type="text"
                    label="Nome da empresa"
                    value={empresa}
                    onChange={(e) => setEmpresa(e.target.value)}
                    error={validateFields && handleValidate(!empresa)}
                    errorMsg="Esta informação é muito importante para nós."
                  />
                </div>
              </div>
              <div className="form__recaptcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcQqMQZAAAAAN79k2S50sBPmu_eyl0dSAhm0nHl"
                  onChange={(e) => setRecaptcha(e)}
                />
              </div>
              <div className="button-block">
                <Button type="submit">Enviar</Button>
              </div>
              <p className="phone">
                Ou ligue
                <a
                  target="blank"
                  href="tel:+2134966905"
                  className="content-card"
                >
                  (21) 3496-6905
                </a>
                (Seg a sex de 9 às 18)
              </p>
            </form>
          </div>
        </section>
        <Accordion
          headers={headersAccordion}
          questions={questionsAccordion}
          activeBtnClass="activeBtn"
        />
        <section className="container CreditPrivate__next-page">
          <Button onClick={handleNextPage}>Fale conosco</Button>
        </section>
      </Container>
      <Message
        onClick={() => setShowMessage(false)}
        state={showMessage}
        icon={IcCheck}
        text="Sua mensagem foi enviada com sucesso! Por favor, aguarde o nosso contato."
        buttonText="OK"
      />
    </Layout>
  );
};

export default CreditPrivate;
