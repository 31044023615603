import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.section`
  padding-top: 80px;
  background: ${({ background }) => (background ? background : '#fff')};

  ${mediaQuery.desktop`
    padding-top: 150px;
  `}

  h2 {
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
    color: ${({ theme }) => theme.font.second};
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;

    ${mediaQuery.desktop`
      font-size: 40px;
    `}
  }

  p {
    max-width: 480px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: ${({ theme }) => theme.font.primary};
    text-align: center;
    letter-spacing: 0.8px;
    margin-bottom: 50px;

    ${mediaQuery.desktop`
        max-width: 650px;
        font-size: 16px;
        padding: 0 20px;
        margin-bottom: 100px;
    `}
  }

  .title-section {
    display: flex;
    justify-content: center;
  }
`;
