

export const contentCreditPrivate = {
  howWork: [
    {
      text: 'A empresa formaliza o convênio e assina o contrato de forma digital.'
    },
    {
      text: 'O colaborador faz a simulação e contrata o empréstimo pelo App ou telefone.'
    },
    {
      text: 'A empresa averba a operação através do Portal Zipdin ou informa previamente as margens disponíveis.'
    },
    {
      text: 'O colaborador recebe o crédito em sua conta.'
    },
  ],
  
};

export const headersAccordion = [
  {
    btnText: "Empresa",
  },
  {
    btnText: "Colaborador",
  },
];

export const questionsAccordion = [
  {
    content: [
      {
        id: 0,
        title:
          "Já possuímos um Banco que faz a folha de pagamento e oferece Consignado, isso é um problema?",
        text:
          "Não, o Banco Central não permite nenhum tipo de exclusividade em qualquer produto bancário. A entrada de uma segunda Instituição Financeira na operação de Consignado faz com que a concorrência entre as duas instituições aumente e quem sai ganhando é o seu colaborador com redução das taxas.",
      },
      {
        id: 1,
        title: "A empresa responde como solidária ou avalista da operação?",
        text:
          "Não, a empresa não responde pelos empréstimos de seus colaboradores. Enquanto em folha de pagamento, a obrigação da empresa é descontar e repassar a Instituição Financeira. No caso de afastamento ou desligamento da empresa é necessária a movimentação dentro do Sistema Zipdin e a cobrança é feita diretamente ao Colaborador.",
      },
      {
        id: 2,
        title: "O sistema deve ser instalado no computador da empresa?",
        text:
          "Toda nossa plataforma é via Web. Firmado o convênio, a empresa recebe login e senha para nosso site e lá faz a gestão completa do produto.",
      },
      {
        id: 3,
        title: "Existe algum custo para ser conveniado Zipdin?",
        text:
          "A Zipdin não cobra nada pelo convênio ao empregador. A parceria é feita justamente para que os colaboradores das empresas privadas tenham acesso ao crédito.",
      },
    ],
  },
  {
    content: [
      {
        id: 0,
        title: "Como funciona o empréstimo Consignado?",
        text:
          "O empréstimo consignado funciona como um empréstimo comum, com a diferença de que todo mês, as parcelas da dívida são debitadas diretamente do seu contracheque e repassadas para a Institução Financeira pela empresa empregadora. Dessa forma, enquanto você estiver pagando o empréstimo não poderá evitar esse desconto. “É uma garantia que os bancos e as financeiras têm de que receberão as parcelas em dia, o que traz menos riscos e permite juros menores do que cobrariam em um empréstimo pessoal ou cartão de crédito por exemplo.",
      },
      {
        id: 1,
        title:
          "Estou negativado, mesmo assim consigo ter acesso ao Consignado?",
        text:
          "'Com a Zipdin você vai ter acesso ao Consignado mesmo com negativações. Todas as condições são informadas no momento da simulação do Consignado quando feita análise de Crédito.",
      },
      {
        id: 2,
        title: "Em caso de desligamento como posso pagar meu Consignado?",
        text:
          "É simples! Em sua rescisão a empresa pode descontar até 30% do líquido para amortizar ou quitar seu Consignado (dependendo do saldo devedor). Caso não seja quitado, ainda existe a possibilidade da adesão do Seguro Prestamista que cobre até 5 parcelas no caso de desligamento. Caso ainda tenha saldo devedor, deverá ser pago através de boleto bancário.",
      },
      {
        id: 3,
        title:
          "Utilizei toda minha margem e preciso de um novo empréstimo, o que devo fazer?",
        text:
          "Dependendo do número de parcelas pagas é possível fazer um refinanciamento da proposta inicial com a liberação de mais um valor na conta. Lembramos que antes de solicitar um novo empréstimo é preciso entender a situação financeira como um todo e trabalhar para redução de gastos.",
      },
    ],
  },
];



export const headersBenefit = [
  {
    btnText: "Empresa",
  },
  {
    btnText: "Colaborador",
  },
];

export const benefitsCompany = [
  {  
    content: [
      {
        text: 'Gestão online com processos simples e automatizados'
      },
      {
        text: 'Isenção de responsabilidade em caso de parcelas não pagas (desligamento ou afastamento)'
      },
      {
        text: 'Boleto para repasse mensal com vencimento para 5 dias após o pagamento da folha'
      },
      {
        text: 'Possibilidade de integração à base de funcionários'
      },
      {
        text: 'Crédito para colaboradores com restrições'
      },
      {
        text: 'Controle total sobre a contratação'
      },
      {
        text: 'Seguro de Crédito para proteger seu colaborador'
      },
      {
        text: 'Atendimento Zipdin ágil e qualificado'
      },
    ]
  },
  {  
    content: [
      {
        text: 'Taxas menores'
      },
      {
        text: 'Prestações fixas e prazos longos'
      },
      {
        text: 'Crédito para negativados'
      },
      {
        text: 'Atendimento e plantões dentro das empresas'
      },
      {
        text: 'liberação apôs averbação do RH'
      },
      {
        text: 'Palestras, suporte e consultoria financeira'
      },
      {
        text: 'Seguro Prestamista para imprevistos'
      },
      {
        text: 'Desconto na quitação em caso de desligamento'
      },
    ]
  }
];