import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.section`
  height: auto;
  background-image: ${({ imageBg }) => `url(${imageBg})`};
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 800px;
  background-size: contain;

  ${mediaQuery.desktop`
    height: ${({ height }) => (height ? `${height}px` : '770px')};
    background-image: ${({ imageBg }) => `url(${imageBg})`};
    background-repeat: no-repeat;
    background-position: ${({ orientation }) => orientation};
    background-size: contain;
  `}
`;
