import React from 'react';

import { Container } from './styles';

import { highlightProps } from './interface';

const HighLight: React.FC<highlightProps> = ({
  className,
  orientation,
  height,
  imageBg,
  children,
}) => {
  return (
    <Container
      className={className}
      orientation={orientation}
      height={height}
      imageBg={imageBg}
    >
      {children}
    </Container>
  );
};

export default HighLight;
