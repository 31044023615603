type templateEmailProps = {
  name: string;
  email: string;
  fone: string;
  empresa: string;
}

export default function templateEmail({ name, email, fone, empresa }: templateEmailProps) {
  const mensage = {
    assunto: "Cadastro Empresa",
    mensagem: "Olá, você recebeu um novo pré-cadastro a partir do site da Zipdin:<br><br>:" +
      "<br>Nome: " + name +
      "<br>Endereço de e-mail: " + email +
      "<br>Número de telefone celular: " + fone +
      "<br>Empresa: " + empresa +
      "<br>",
    email: "comercial@zipdin.com.br"
  };

  return mensage;
}