import React from "react";

import ItemAccordion from "./ItemAccordion";

import { Container } from "./styles";

const Accordion = ({ headers, questions, activeBtnClass }) => {
  const [contentCards, setContentCards] = React.useState(questions);
  const [active, setActive] = React.useState(0);

  React.useEffect(() => {
    setContentCards(questions[0].content);
  }, []);

  function handleSelection(i) {
    setContentCards(questions[i].content);
    setActive(i);
  }

  return (
    <Container>
      <h1 className="accordion__title">
        Tire suas dúvidas sobre o Consignado Privado Zipdin
      </h1>
      <div className="accordion__headers">
        {headers &&
          headers.map((header, i) => (
            <button
              className={`accordion__headersBtn ${
                active === i ? activeBtnClass : ""
              }`}
              key={header.btnText}
              type="button"
              onClick={() => handleSelection(i)}
            >
              {header.btnText}
            </button>
          ))}
      </div>
      <div className="container">
        {contentCards.map((question) => (
          <ItemAccordion
            className={`item-${question.id}`}
            key={question.id}
            question={question}
          />
        ))}
      </div>
    </Container>
  );
};

export default Accordion;
