import React from "react";

import { Container } from "./styles";

import NumericListProps from "./interface";

const NumericList = ({ item, image, className }: NumericListProps) => {
  return (
    <Container image={image} className={className}>
      <div className="container">
        <ul>
          {item?.map((item, index) => (
            <li key={index}>
              {image ? (
                <img src={image} alt="imagem de icone da listagem" />
              ) : (
                <span>{index + 1}</span>
              )}
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

export default NumericList;
