import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.section`
  height: auto;
  padding: 0 0 60px;
  width: 100%;
  background: ${({ theme }) => theme.bg.second};
  background-image: url(${props => props.backgroundImg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 800px;
  background-size: contain;

  ${mediaQuery.desktop`
    height: 800px;
    padding: 0 ;
    background-position: right;
    background-size: 100% 800px;
    background-size: contain;
  `}

  .container-jumbotron {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .info-banner {
    padding: 38px;
    margin-top: 194px;
    
    ${mediaQuery.desktop`
      margin: 0;
      padding: 80px;
    `}
  }

  .btn-banner-home {
    width: 100%;
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;

    ${mediaQuery.desktop`
      width: auto;
    `}
  }
`;
