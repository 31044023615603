import React from "react";

import { Container } from "./styles";

import { titleSectionProps } from "./interface";

const TitleSection = ({
  title,
  text,
  className,
  children,
}: titleSectionProps) => {
  return (
    <Container className={`container ${className}`}>
      <article className="title-section">
        <div className="col-md-10 justifyment-column">
          <h2>{title}</h2>
          <p>{text}</p>
          {children}
        </div>
      </article>
    </Container>
  );
};

export default TitleSection;
