import React from "react";

import { Container, Text } from "./styled";

const ItemAccordion = ({ question, className }) => {
  const [hidden, setHidden] = React.useState(true);
  const [height, setHeight] = React.useState(0);

  function handleHeigth(id) {
    const height = document.querySelector(`.item-${id} p`).clientHeight;
    setHeight(height);
    setHidden(!hidden);
  }

  return (
    <Container
      hidden={hidden}
      height={height}
      className={className}
      key={question.title}
      onClick={() => handleHeigth(question.id)}
    >
      <div className="accordion__content">
        <h1 className="accordion__listTitle">{question.title}</h1>
        <div className="accordion__hidden">
          <Text className="accordion__listText">{question.text}</Text>
        </div>
      </div>
      <button className="accordion__btnClose">+</button>
    </Container>
  );
};

export default ItemAccordion;
