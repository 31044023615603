import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';


export const Container = styled.article`

  margin: 0 auto;
  width: 100%;
  position: relative;

  ${mediaQuery.desktop`
    width: 562px;
    position: relative;
    margin-top: -40px;
  `}

  .accordion__headers {
    display: flex;
    justify-content: center;
    

    .accordion__headersBtn {
      margin: 40px 8px 50px;
      font-size: 12px;
      padding: 8px 43px;
      color: #23797B;
      border: 2px solid transparent;
      border-radius: 3px;
      background: transparent;
      transition: .6s;
      font-weight: 600;

      ${mediaQuery.desktop`
        font-size: 16px;
        padding: 15px 43px;
      `}

      &.active {
        border: 2px solid ${({ theme }) => theme.font.third}!important;
      }
    }
  }
  
  li {
    font-size: 16px;
    color: #757474;
    margin-bottom: 30px;
    padding-left: 52px;
    padding-top: 2px;
    position: relative;

    span {
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      /* border-radius: 50% 50%;
      background: #CCDD00; */
      color: #fff;
      font-size: 20px;
      text-align: center;
      line-height: 32px;
    }
  }
`;
